import Core from '@core';
import theme from '@themes/ora';
import { ConfigProvider } from 'ni-ui-kit/dist/context';
import { KeycloakAuthService } from '@services';

export function App() {
  return (
    <KeycloakAuthService>
      <ConfigProvider theme={theme.niUiKitTheme}>
        <Core theme={theme.theme} />
      </ConfigProvider>
    </KeycloakAuthService>
  );
}

export default App;

import './locales/i18n';
import { Suspense } from 'react';
import { App as AntdApp } from 'antd';
import type { ThemeConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import React, { StrictMode, useEffect } from 'react';
import { ViewportProvider } from 'ni-ui-kit/dist/context';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import router from './router';
import { ThemeProvider } from '@providers';
import CenteredSpin from './components/CenteredSpin';
import { HttpClientInterceptor } from './lib/httpClient';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type props = {
  theme?: ThemeConfig;
};

const App: React.FC<props> = ({ theme }) => {
  const { i18n } = useTranslation();
  const direction = i18n.dir();

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      document.documentElement.lang = lng;
      document.body.dir = i18n.dir(lng);
      localStorage.setItem('locale', lng);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n]);

  useEffect(() => {
    const lang = localStorage.getItem('locale');
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  return (
    <StrictMode>
      <HttpClientInterceptor />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme} direction={direction}>
          <Suspense fallback={<CenteredSpin />}>
            <ViewportProvider>
              <AntdApp>
                <RouterProvider router={router} />
              </AntdApp>
            </ViewportProvider>
          </Suspense>
        </ThemeProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </StrictMode>
  );
};

export default App;

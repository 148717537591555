import { ICard } from '@dtos';

import { CardStatus } from './CardStatus';
import { ObjectCopier } from './ObjectCopier';

enum AvailableActions {
  ACTIVATE = 'activate',
  BLOCK = 'block',
  CLOSE = 'close',
  UNBLOCK = 'unblock',
  REPLACE = 'replace',
  VIEW = 'view',
  SECURE_DETAILS = 'secure-details',
  SET_PIN = 'pin-set',
  CARD_CONTROL = 'card-control',
}

export class Card extends ObjectCopier {
  id: string;
  img: string;
  cardType: string;
  balance: number;
  status: CardStatus;
  currency: string;
  number: string;
  expiryDate?: Date;
  maskedPan: string;
  cvv?: string;
  pan?: string;
  cardHolderName?: string;
  availableActions: AvailableActions[];
  isVirtual: boolean;

  constructor(data: ICard) {
    super();
    const parsedExpiryDate = data?.expiryDate
      ? new Date(data?.expiryDate)
      : undefined;

    this.id = data.cardIdentifier;
    this.cardType = data.product || '-';
    this.balance = data.balance;
    // should be based on the card brand name
    this.img = '';
    this.status = new CardStatus(data.cardStatus);
    this.expiryDate = parsedExpiryDate;
    // could be data.number || data.maskedPan if number is available
    this.number = data.maskedPan;
    this.maskedPan = data.maskedPan;
    this.currency = data.currencyCode;
    this.availableActions = [];
    this.cardHolderName = data.cardholderName;
    this.isVirtual = data.isVirtual;

    if (Array.isArray(data.availableActions)) {
      if (data.availableActions.length > 0) {
        for (const action of data.availableActions[0].scopes) {
          switch (action) {
            case AvailableActions.ACTIVATE:
              this.availableActions.push(AvailableActions.ACTIVATE);
              break;
            case AvailableActions.BLOCK:
              this.availableActions.push(AvailableActions.BLOCK);
              break;
            case AvailableActions.CLOSE:
              this.availableActions.push(AvailableActions.CLOSE);
              break;
            case AvailableActions.UNBLOCK:
              this.availableActions.push(AvailableActions.UNBLOCK);
              break;
            case AvailableActions.REPLACE:
              this.availableActions.push(AvailableActions.REPLACE);
              break;
            case AvailableActions.VIEW:
              this.availableActions.push(AvailableActions.VIEW);
              break;
            case AvailableActions.SECURE_DETAILS:
              this.availableActions.push(AvailableActions.SECURE_DETAILS);
              break;
            case AvailableActions.SET_PIN:
              this.availableActions.push(AvailableActions.SET_PIN);
              break;
            case AvailableActions.CARD_CONTROL:
              this.availableActions.push(AvailableActions.CARD_CONTROL);
              break;
            default:
              break;
          }
        }
      }
    }
  }

  static toList(data: ICard[]) {
    if (Array.isArray(data)) {
      return data.map((c) => new Card(c));
    }
    return [];
  }
}

export { AvailableActions as CardAvailableActions };
